// components/Game.js
import React, { useState, useEffect } from 'react';
import './Game.css';

const Game = () => {
  const [player1Position, setPlayer1Position] = useState({ top: 50, left: 10 });
  const [player2Position, setPlayer2Position] = useState({ top: 50, left: 80 });
  const [player1Health, setPlayer1Health] = useState(100);
  const [player2Health, setPlayer2Health] = useState(100);
  const [gameOver, setGameOver] = useState(false);
  const [winner, setWinner] = useState('');
  const [player1Bullets, setPlayer1Bullets] = useState([]);
  const [player2Bullets, setPlayer2Bullets] = useState([]);

  const movePlayer = (e) => {
    if (gameOver) return;

    // Player 1 controls
    if (e.key === 'w') setPlayer1Position((prev) => ({ ...prev, top: Math.max(prev.top - 5, 0) }));
    if (e.key === 's') setPlayer1Position((prev) => ({ ...prev, top: Math.min(prev.top + 5, 80) }));
    if (e.key === 'a') setPlayer1Position((prev) => ({ ...prev, left: Math.max(prev.left - 5, 0) }));
    if (e.key === 'd') setPlayer1Position((prev) => ({ ...prev, left: Math.min(prev.left + 5, 80) }));

    // Player 2 controls
    if (e.key === 'ArrowUp') setPlayer2Position((prev) => ({ ...prev, top: Math.max(prev.top - 5, 0) }));
    if (e.key === 'ArrowDown') setPlayer2Position((prev) => ({ ...prev, top: Math.min(prev.top + 5, 80) }));
    if (e.key === 'ArrowLeft') setPlayer2Position((prev) => ({ ...prev, left: Math.max(prev.left - 5, 20) }));
    if (e.key === 'ArrowRight') setPlayer2Position((prev) => ({ ...prev, left: Math.min(prev.left + 5, 100) }));

    // Player 1 shooting
    if (e.key === 'f') {
      setPlayer1Bullets((prev) => [...prev, { top: player1Position.top, left: player1Position.left + 5 }]);
    }

    // Player 2 shooting
    if (e.key === 'Enter') {
      setPlayer2Bullets((prev) => [...prev, { top: player2Position.top, left: player2Position.left - 5 }]);
    }
  };

  const moveBullets = () => {
    setPlayer1Bullets((prev) => prev.map(bullet => ({ ...bullet, left: bullet.left + 2 })).filter(bullet => bullet.left <= 100));
    setPlayer2Bullets((prev) => prev.map(bullet => ({ ...bullet, left: bullet.left - 2 })).filter(bullet => bullet.left >= 0));
  };

  const checkBulletCollision = (bullets, playerPosition, setHealth) => {
    bullets.forEach((bullet) => {
      if (
        bullet.left < playerPosition.left + 5 &&
        bullet.left + 2 > playerPosition.left &&
        bullet.top < playerPosition.top + 5 &&
        bullet.top + 2 > playerPosition.top
      ) {
        setHealth((prev) => Math.max(prev - 10, 0));
      }
    });
  };

  useEffect(() => {
    if (player1Health <= 0) {
      setGameOver(true);
      setWinner('Player 2');
    } else if (player2Health <= 0) {
      setGameOver(true);
      setWinner('Player 1');
    }
  }, [player1Health, player2Health]);

  useEffect(() => {
    document.addEventListener('keydown', movePlayer);
    const interval = setInterval(moveBullets, 100);

    return () => {
      document.removeEventListener('keydown', movePlayer);
      clearInterval(interval);
    };
  }, [player1Position, player2Position]);

  useEffect(() => {
    checkBulletCollision(player1Bullets, player2Position, setPlayer2Health);
    checkBulletCollision(player2Bullets, player1Position, setPlayer1Health);
  }, [player1Bullets, player2Bullets]);

  return (
    <div className="game-container">
      {gameOver ? (
        <div className="game-over">
          <h2>Game Over!</h2>
          <p>{winner} Wins!</p>
          <button onClick={() => window.location.reload()}>Restart Game</button>
        </div>
      ) : (
        <>
          <div
            className="player player1"
            style={{ top: `${player1Position.top}%`, left: `${player1Position.left}%` }}
          ></div>
          <div
            className="player player2"
            style={{ top: `${player2Position.top}%`, left: `${player2Position.left}%` }}
          ></div>
          <div className="health-bar player1-health">
            <span style={{ width: `${player1Health}%` }}></span>
          </div>
          <div className="health-bar player2-health">
            <span style={{ width: `${player2Health}%` }}></span>
          </div>

          {player1Bullets.map((bullet, index) => (
            <div key={index} className="bullet" style={{ top: `${bullet.top}%`, left: `${bullet.left}%` }}></div>
          ))}
          {player2Bullets.map((bullet, index) => (
            <div key={index} className="bullet" style={{ top: `${bullet.top}%`, left: `${bullet.left}%` }}></div>
          ))}
        </>
      )}
    </div>
  );
};

export default Game;
